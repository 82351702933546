
let expiry = sessionStorage.getItem('alert_expiry');
let firstVisit;

if( expiry === null ) {
    sessionStorage.setItem('alert_expiry', Date.now() + 25000);
    firstVisit = true }
else { 
    firstVisit = false
}

expiry = sessionStorage.getItem('alert_expiry');

const alertBox = document.querySelector('.alert-card') ? document.querySelector('.alert-card') : false;
if( alertBox ) {
    const timeLeft = expiry - Date.now();
    if(timeLeft > 0) {
        alertBox.style.display = 'block';
        firstVisit ? setTimeout( () => { alertBox.classList.add('active') }, 200) : alertBox.classList.add('active')

        const expire = setTimeout(() => {
            alertBox.classList.remove('active')
            removeEventListener('click', closeOnClick);
        }, timeLeft);

        const closeOnClick = alertBox.querySelector('svg').addEventListener('click', () => {
            sessionStorage.setItem('alert_expiry', 1);
            alertBox.classList.remove('active');
            clearTimeout(expire);
            removeEventListener('click', closeOnClick);
        })
    }
}