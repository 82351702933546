jQuery('.menu-top-menu-container .clock-link').on('mouseenter', function(){
  jQuery('.image-change .watch-image').fadeOut();
  jQuery('.image-change .clock-image').fadeIn();
});

jQuery('.menu-top-menu-container .watch-link').on('mouseenter', function(){
  jQuery('.image-change .clock-image').fadeOut();
  jQuery('.image-change .watch-image').fadeIn();
});

jQuery('.homepage-banner .arrow').on('click', function() {
  jQuery('html, body').animate({ scrollTop: jQuery('.service-card').offset().top - 200}, 1000);
});