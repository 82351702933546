jQuery('.burgerBun').on('click touch', function() {
  jQuery('.side-menu').toggleClass("open");
});
jQuery('.menu-side-menu-container a').on('click touch', function() {
  jQuery('.side-menu').removeClass("open");
});
jQuery('.side-menu .close-menu').on('click touch', function() {
  jQuery('.side-menu').removeClass("open");
});

jQuery(window).on("scroll resize", function() {
  if (jQuery(document).scrollTop() > 5) {
    jQuery('header').addClass('scroll');
  }
  else {
    jQuery('header').removeClass('scroll');
  }
});