jQuery('.team-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    autoplay: false,
    dots: false,
    rows: 0,
    centerMode: true,
    centerPadding: '10%',
    focusOnSelect: false,
    prevArrow:'<svg class="slick-arrow slick-prev" width="28" height="35" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-left fa-w-8"><path fill="#DBB13B" d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z" class=""></path></svg>',
    nextArrow:'<svg class="slick-arrow slick-next" width="28" height="35" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-right fa-w-8"><path fill="#DBB13B" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z" class=""></path></svg>',
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                centerPadding: '5%'
            }
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                centerPadding: '0'
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                centerMode: false,
                centerPadding: '0'
            }
        },
        {
            breakpoint: 639,
            settings: {
                slidesToShow: 1,
                centerMode: false,
                centerPadding: '0'
            }
        },
        {
            breakpoint: 440,
            settings: {
                slidesToShow: 1,
                centerMode: false,
                centerPadding: '0'
            }
        }
    ]
});

jQuery('.bio-open').on('click touch', function(){
    jQuery(this).parent('.team-name').siblings('.bio').addClass('open');
}); 

jQuery('.bio-close').on('click touch', function(){
    jQuery(this).parent('.bio').removeClass('open');
});

jQuery('.team-slider .slick-arrow').on('click touch', function(){
    jQuery('.team-slider .team-card .bio').removeClass('open');
});