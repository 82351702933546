import 'slick-carousel';
import 'slick-lightbox';
import AOS from 'aos';

AOS.init( {
    once: true,
    duration: 500,
    anchorPlacement: 'top-bottom',
} );

import './template-parts/utility.js';
import './template-parts/global.js';
import './template-parts/footer.js';
import './template-parts/header.js';
import './template-parts/brands.js';
import './template-parts/content-slider';
import './template-parts/quote.js';
import './template-parts/faqs.js';
import './template-parts/single-post.js';
import './template-parts/team.js';
import './template-parts/homepage-banner.js';
import './template-parts/alert-card.js';

AOS.refresh();