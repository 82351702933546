jQuery('.contact-button').on('click touch', function(){
    jQuery('.contact-canvas').addClass('open');
});

jQuery('.close-contact').on('click touch', function(){
    jQuery('.contact-canvas').removeClass('open');
});

jQuery('.contact-link').on('click touch', function(){
    jQuery('html, body').animate({
        scrollTop: jQuery('footer').offset().top-80
    }, 350);
});

jQuery('.book-link').on('click touch', function(){
    jQuery('html, body').animate({
        scrollTop: jQuery('.quote-block').offset().top-80
    }, 350);
});

const isMediaUp = window.matchMedia('(min-width: 1000px)');
let logosSlicked = false;
function handleLogoSlider() {
    if ( isMediaUp.matches && logosSlicked ) {
        jQuery('.brand-logos-slider').slick('unslick');
        logosSlicked = false;
    } else if (!isMediaUp.matches && !logosSlicked) {
        jQuery('.brand-logos-slider').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "5%",
            variableWidth: true,
            arrows: false,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                        variableWidth: true,
                    },
                },
                {
                    breakpoint: 650,
                    settings: {
                        slidesToShow: 2,
                        variableWidth: true,
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: false,
                    }
                }
            ]
        });
        logosSlicked = true;
    }
}
handleLogoSlider();
isMediaUp.addEventListener( 'change', handleLogoSlider );

