import { get, getAll } from './utility';

////////////////////////////////////////////////////////////////////////
///////////////////////////      WATCHES     ///////////////////////////
////////////////////////////////////////////////////////////////////////

// Initial Watches or Clocks selection
jQuery( '.product-select button.selector' ).on( 'click touch', function() {
    jQuery( this ).addClass( 'active' );
    jQuery( this ).siblings().removeClass( 'active' );
    jQuery( '.product-select button.next' ).removeAttr( 'disabled' );

    if ( jQuery( '.product-select .selector.watches' ).hasClass( 'active' ) ) {
        jQuery( '.quote-block .clock-image' ).fadeOut();
        jQuery( '.quote-block .watch-image' ).fadeIn();
    }

    if ( jQuery( '.product-select .selector.clocks' ).hasClass( 'active' ) ) {
        jQuery( '.quote-block .watch-image' ).fadeOut();
        jQuery( '.quote-block .clock-image' ).fadeIn();
    }
} );

// Initial Watches or Clocks selection Next button click
jQuery( '.product-select button.next' ).on( 'click touch', function() {
    if ( jQuery( '.product-select button.selector' ).hasClass( 'active' ) ) {
        jQuery( '.start-block.stage-container' ).addClass( 'hidden' );

        if ( jQuery( '.product-select .watches' ).hasClass( 'active' ) ) {
            jQuery( '.watch-block.stage-container' ).removeClass( 'hidden' );
        } else {
            jQuery( '.clock-type.stage-container' ).removeClass( 'hidden' );
        }
    }
} );

// Initial Watches and Clocks section Back button click
jQuery( '.watch-block button.back' ).on( 'click touch', function() {
    jQuery( '.watch-block.stage-container' ).addClass( 'hidden' );
    jQuery( '.start-block.stage-container' ).removeClass( 'hidden' );
} );
jQuery( '.clock-type button.back' ).on( 'click touch', function() {
    jQuery( '.clock-type.stage-container' ).addClass( 'hidden' );
    jQuery( '.start-block.stage-container' ).removeClass( 'hidden' );
} );

// Watches Brand selection
jQuery( '.brand' ).on( 'change', function() {
    jQuery( this ).find( ':selected' ).addClass( 'selected' )
        .siblings( 'option' ).removeClass( 'selected' );
} );

// Watches Brand and Model input check on Next button click
jQuery( '.watch-block button.next' ).on( 'click touch', function() {
    if ( jQuery( '.watch-block .brand .default' ).hasClass( 'selected' ) ) {
        jQuery( '.watch-block .brand-error' ).removeClass( 'hidden' );
    } else {
        jQuery( '.watch-block .brand-error' ).addClass( 'hidden' );
    }

    if ( jQuery( '.watch-block .brand .brandname' ).hasClass( 'selected' ) ) {
        jQuery( '.watch-block.stage-container' ).addClass( 'hidden' );
        jQuery( '.watch-work.stage-container' ).removeClass( 'hidden' );
    }
} );

// Watch service type selection
getAll( '.watch-work [data-service]' ).forEach( button => {
    button.addEventListener( 'click', () => {
        button.classList.toggle( 'active' );
    } );
} );

// Watch service type section Back button click
jQuery( '.watch-work button.back' ).on( 'click', function() {
    jQuery( '.watch-work.stage-container' ).addClass( 'hidden' );
    jQuery( '.watch-block.stage-container' ).removeClass( 'hidden' );
} );

// Watch service type section Next button click validation and action
jQuery( '.watch-work button.next' ).on( 'click', function() {
    const submitButton = get('.watch-service .wpcf7-submit');
    const brand = get('.watch-block .brand').value;
    const model = get( '.watch-block .model' ).value;
    const serviceElements = Array.from( getAll( '.watch-work [data-service]' ) );
    const selectedServiceButtons = serviceElements.filter( ser => ser.classList.contains( 'active' ) );

    // Display error if no service selected else ensure error is hidden
    if ( selectedServiceButtons.length === 0 ) return jQuery( '.watch-work-error' ).removeClass( 'hidden' );
    else jQuery( '.watch-work-error' ).addClass( 'hidden' );

    // Change submit button ID for analytics tracking purposes
    submitButton.id = selectedServiceButtons.length === 1 ? 'watch-' + selectedServiceButtons[0].dataset['service'] : 'watch-multiple';

    // Construct a string from the chosen services
    const servicesString = selectedServiceButtons.map(ser => ser.textContent).join(', ');

    // Set hidden form fields with required info
    get('.watch-service__message').innerText = servicesString;
    get( '.watch-service form input.your-brand' ).value = brand;
    get( '.watch-service form input.your-model' ).value = model;
    get( '[name=required-services]' ).value = servicesString;

    jQuery( '.watch-work.stage-container' ).addClass( 'hidden' );
    jQuery( '.watch-service.stage-container' ).removeClass( 'hidden' );
} );

// Watch service type section Back button click
jQuery( '.watch-service .button.back' ).on( 'click', function() {
    jQuery( '.watch-service.stage-container' ).addClass( 'hidden' );
    jQuery( '.watch-work.stage-container' ).removeClass( 'hidden' );
} );



////////////////////////////////////////////////////////////////////////
///////////////////////////      ClOCKS     ////////////////////////////
////////////////////////////////////////////////////////////////////////

// Clock type selection
jQuery( '.clock-type button.selector' ).on( 'click touch', function() {
    jQuery( this ).addClass( 'active' );
    jQuery( this ).siblings().removeClass( 'active' );
} );

// Clock type selection section Back button click
jQuery( '.clock-type button.back' ).on( 'click touch', function() {
    jQuery( '.clock-type.stage-container' ).addClass( 'hidden' );
    jQuery( '.start-block.stage-container' ).removeClass( 'hidden' );
} );

// Clock type selection section Next button click validation and action
jQuery( '.clock-type button.next' ).on( 'click touch', function() {
    if ( !jQuery( '.clock-type-select .selector' ).hasClass( 'active' ) ) {
        jQuery( '.clock-type-error' ).removeClass( 'hidden' );
    } else {
        jQuery( '.clock-type-error' ).addClass( 'hidden' );
    }

    if ( jQuery( '.clock-type-select .selector' ).hasClass( 'active' ) ) {
        jQuery( '.clock-type.stage-container' ).addClass( 'hidden' );

        if ( jQuery( '.clock-type-select .selector.tower' ).hasClass( 'active' ) ) {
            jQuery( '.tower-service.stage-container' ).removeClass( 'hidden' );
        } else {
            jQuery( '.clock-brand-model.stage-container' ).removeClass( 'hidden' );
        }
    }
} );

jQuery( '.clock-tick button.selector' ).on( 'click touch', function() {
    jQuery( this ).addClass( 'active' );
    jQuery( this ).siblings().removeClass( 'active' );
} );

jQuery( '.clock-brand-model button.next' ).on( 'click touch', function() {
    if ( !jQuery( '.clock-brand-model .selector' ).hasClass( 'active' ) ) {
        jQuery( '.clock-tick-error' ).removeClass( 'hidden' );
    } else {
        jQuery( '.clock-tick-error' ).addClass( 'hidden' );
    }

    if ( jQuery( '.clock-brand-model .selector' ).hasClass( 'active' ) ) {
        jQuery( '.clock-brand-model.stage-container' ).addClass( 'hidden' );
        jQuery( '.clock-work-choice.stage-container' ).removeClass( 'hidden' );
    }
} );


// Clock make/model section Back button click
jQuery( '.clock-brand-model button.back' ).on( 'click touch', function() {
    jQuery( '.clock-brand-model.stage-container' ).addClass( 'hidden' );
    jQuery( '.clock-type.stage-container' ).removeClass( 'hidden' );
} );

// Clock service type selection
jQuery( '.clock-work-choice button.selector' ).on( 'click touch', function() {
    jQuery( this ).addClass( 'active' );
    jQuery( this ).siblings().removeClass( 'active' );
} );

// Clock work choice section Back button click
jQuery( '.clock-work-choice button.back' ).on( 'click touch', function() {
    jQuery( '.clock-work-choice.stage-container' ).addClass( 'hidden' );
    jQuery( '.clock-brand-model.stage-container' ).removeClass( 'hidden' );
} );

// Clock service type section Next button click validation and action
jQuery( '.clock-work-choice button.next' ).on( 'click touch', function() {
    let clockMake = jQuery( '.clock-brand-model .make' ).val();
    let clockModel = jQuery( '.clock-brand-model .model' ).val();

    jQuery( '.clock-repair form .your-make' ).attr( 'value', clockMake );
    jQuery( '.clock-repair form .your-model' ).attr( 'value', clockModel );

    jQuery( '.clock-refurbishment form .your-make' ).attr( 'value', clockMake );
    jQuery( '.clock-refurbishment form .your-model' ).attr( 'value', clockModel );


    if ( !jQuery( '.clock-work-choice .selector' ).hasClass( 'active' ) ) {
        jQuery( '.clock-work-select-error' ).removeClass( 'hidden' );
    } else {
        jQuery( '.clock-work-select-error' ).addClass( 'hidden' );
    }

    if ( jQuery( '.clock-work-choice .selector' ).hasClass( 'active' ) ) {
        jQuery( '.clock-work-choice.stage-container' ).addClass( 'hidden' );

        if ( jQuery( '.clock-work-choice .selector.repair' ).hasClass( 'active' ) ) {
            jQuery( '.clock-repair' ).removeClass( 'hidden' );
        }
        if ( jQuery( '.clock-work-choice .selector.refurbishment' ).hasClass( 'active' ) ) {
            jQuery( '.clock-refurbishment' ).removeClass( 'hidden' );
        }
    }
} );

// Clock Repair form Back button click
jQuery( '.clock-repair .button.back' ).on( 'click touch', function() {
    jQuery( '.clock-work-choice.stage-container' ).removeClass( 'hidden' );
    jQuery( '.clock-repair.stage-container' ).addClass( 'hidden' );
} );

// Clock Refurb form Back button click
jQuery( '.clock-refurbishment .button.back' ).on( 'click touch', function() {
    jQuery( '.clock-work-choice.stage-container' ).removeClass( 'hidden' );
    jQuery( '.clock-refurbishment.stage-container' ).addClass( 'hidden' );
} );

// Tower form Back button click
jQuery( '.tower-service .button.back' ).on( 'click touch', function() {
    jQuery( '.clock-type.stage-container' ).removeClass( 'hidden' );
    jQuery( '.tower-service.stage-container' ).addClass( 'hidden' );
} );